import React from 'react';

import CustomLink from '@components/global/CustomLink';

import styles from './Button.module.css';

interface ButtonProps {
    color?: 'yellow' | 'black' | 'orange' | 'blue' | 'ghost' | 'max-orange' | 'ghost-blue' | 'max-black' | 'black-white' | 'blue-yellow';
    label: string | JSX.Element;
    size?: 'small' | 'medium' | 'large';
    href?: string;
    customLink?: JSX.Element;
    type?: 'button' | 'submit';
    disabled?: boolean;
    className?: string;
    arrow?: boolean;
    onClick?: (e: React.MouseEvent) => void;
}

export const Button = ({ href, customLink, color = 'yellow', label, size = 'medium', type = 'button', disabled = false, className, arrow = true, ...otherProps }: ButtonProps) => {
    if (href) {
        return (
            <a
                {...otherProps}
                href={href}
                className={[styles.btn, className, styles[size], styles[color], arrow ? styles.arrow : null].filter(Boolean).join(' ')}
            >
                <span>{label}</span>
            </a>
        );
    }
    if (customLink) {
        return (
            <CustomLink
                {...customLink.props}
                {...otherProps}
                className={[styles.btn, className, styles[size], styles[color], arrow ? styles.arrow : null].filter(Boolean).join(' ')}
            >
                <span>{label}</span>
            </CustomLink>
        );
    }
    return (
        <button
            {...otherProps}
            type={type}
            disabled={disabled}
            className={[styles.btn, className, styles[size], styles[color], arrow ? styles.arrow : null].filter(Boolean).join(' ')}
        >
            <span>{label}</span>
        </button>
    );
};
